import React from "react";
import styled from "styled-components";

import Text from "src/components/UI/Text";
import Title from "src/components/UI/Typography/Title";

const ContentStyles = styled.div`
  .content {
    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
`;

const Content = ({ title, content, fontSize }) => {
  return (
    <ContentStyles>
      {title && (
        <Title as="h2" mt={0}>
          {title}
        </Title>
      )}
      {content && (
        <Text fontSize={fontSize}>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Text>
      )}
    </ContentStyles>
  );
};

Content.defaultProps = {
  fontSize: [3, null, null, null, 4, 5],
};

export default Content;
