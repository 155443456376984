import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import PostTemplate from "src/components/PageTemplates/PostTemplate/PostTemplate";

const PostPage = ({ data: { wpPost }, location }) => {
  return <PostTemplate gatsbyData={wpPost} location={location} />;
};

export const query = graphql`
  query currentPostQuery($id: String!) {
    wpPost(id: { eq: $id }) {
      content
      link
      date
      title
      acfNewsPage {
        relatedArtist {
          ... on WpArtist {
            databaseId
          }
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  }
`;

// TODO: Prop types
PostPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    wpPost: PropTypes.shape({
      content: PropTypes.string,
      link: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      acfNewsPage: PropTypes.shape({}),
      featuredImage: PropTypes.shape({
        node: PropTypes.shape({
          localFile: PropTypes.shape({
            //         childImageSharp {
            //           fluid(maxWidth: 1920) {
            //             ...GatsbyImageSharpFluid
            //           }
            //         }
          }),
        }),
      }),
    }),
  }),
};

export default PostPage;
