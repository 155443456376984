import { atom } from "recoil";

export const activeMenuState = atom({
  key: "activeMenu",
  default: "",
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newActiveMenu) => {
        if (document) {
          if (newActiveMenu) {
            document.body.classList.add("prevent-overflow");
          } else {
            document.body.classList.remove("prevent-overflow");
          }
        }
      });
    },
  ],
});

export const previewDataState = atom({
  key: "previewData",
  default: {
    post: [],
    posts: [],
    page: [],
    pages: [],
    artist: [],
    artists: [],
    event: [],
    events: [],
    staff: [],
    staffs: [],
    preview: false,
  },
});
