import React from "react";
import styled from "styled-components";

import getImageObject from "src/helpers/getImageObject";

import Image from "src/components/UI/Image";
import Flex from "src/components/UI/Structure/Flex";
import Box from "src/components/UI/Structure/Box";
import Text from "src/components/UI/Text";
import Label from "src/components/UI/Typography/Label";
import Button from "src/components/UI/Button";
import Icon from "src/components/UI/Icon";
import SpotifyIcon from "src/images/svg/icon-spotify.svg";

const ReleaseStyles = styled.div`
  .release-button {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(-50%);
  }

  .release-image {
    padding-bottom: 100%;
    background-color: black;
    position: relative;

    > * {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
    }
  }
`;

const Release = ({ label, release, buttonStyle }) => {
  const { image } = release;

  return (
    <ReleaseStyles>
      {label && <Label mb={[3, null, null, 5]}>{label}</Label>}
      {release && (
        <div className="release">
          <div className="release-image">
            <Image image={getImageObject(image)} />
          </div>

          <div className="release-button">
            <Button
              size="large"
              filled={buttonStyle}
              lowercase
              link={release.spotifyUri}
              alt={release.title}
              linkTarget="_blank"
              label={release.title}
            >
              <Flex width={1} alignItems="center">
                <Text>{release.title}</Text>
                <Box ml={[0, "auto"]} pl={[2, 8]} mr="-8px">
                  <Icon size="small">
                    <SpotifyIcon />
                  </Icon>
                </Box>
              </Flex>
            </Button>
          </div>
        </div>
      )}
    </ReleaseStyles>
  );
};

Release.defaultProps = {
  buttonStyle: "lightGray",
};

export default Release;
